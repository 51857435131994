import './index.css'
import React from 'react';
import {BsArrowRightCircle, BsBuilding} from "react-icons/bs";
import { IoFootballOutline } from "react-icons/io5";
import { IoFootstepsOutline } from "react-icons/io5";
import { IoCarSportOutline } from "react-icons/io5";
import {Link} from "react-router-dom";


const Presentation = () => {
    return (
        <div className="presentation py-5">
            <div className="container py-5 text-center">
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="title">
                        <div><h5>SOLUTII COMPLETE LA PICIOARELE TALE</h5></div>
                        <div className="title-border"></div>
                    </div>

                    <div className="mt-4">
                        Implicarea este esentiala in orice echipa. Fara oameni de calitate, dedicati si ambitiosi, ar fi
                        fost mai greu sa ajungem unde suntem astazi. In prezent, echipa Syntech Floor reuneste peste 55 de
                        persoane cu un potential exceptional, care depun eforturi sustinute de a evolua simultan cu fiecare
                        proiect implementat.
                    </div>

                    <div className="row mt-5 d-flex flex-wrap justify-content-center">
                        <div className="col-lg-5 col-md-6 col-sm-12 px-0">
                            <Link to="/sali-de-sport-fitness" className="text-decoration-none nav-link color-white">
                                <div className="card">
                                    <span className="icons"><IoFootballOutline/></span>
                                    <span className="color-green font card-title">SALI SPORT</span>
                                    <span className="mt-4">Pardoseli pentru suprafetele sportive si zonele publice de agrement</span>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 px-0">
                            <Link to="/spatii-publice-comerciale" className="text-decoration-none nav-link color-white">
                                <div className="card">
                                    <span className="icons"><BsBuilding/></span>
                                    <span className="color-green card-title">SPATII PUBLICE</span>
                                    <span className="mt-4">Pardoseli pentru suprafetele sportive si zonele publice de agrement</span>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 px-0">
                            <Link to="/birouri-zone-urbane" className="text-decoration-none nav-link color-white">
                                <div className="card">
                                    <span className="icons"><IoFootstepsOutline/></span>
                                    <span className="color-green card-title">TRAFIC INTENS</span>
                                    <span className="mt-4">Pardoseli pentru suprafetele sportive si zonele publice de agrement</span>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 px-0">
                            <Link to="/parcari-auto" className="text-decoration-none nav-link color-white">
                                <div className="card">
                                    <span className="icons"><IoCarSportOutline/></span>
                                    <span className="color-green card-title">SUPRAFETE AUTO</span>
                                    <span className="mt-4">Pardoseli pentru suprafetele sportive si zonele publice de agrement</span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="text-center pt-5">
                        <Link to="/servicii" className="button">Citeste mai mult <span className="button-symbol"><BsArrowRightCircle/></span></Link>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Presentation;
