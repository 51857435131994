import React from 'react';
import NavBar from "../Layouts/NavBar";
import Footer from "../Layouts/Footer";
import PortfolioGallery from "../Content/Portfolio";
import {Helmet} from "react-helmet";

const Portfolio = () => {
    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Portofoliul Syntech Flooring</title>
                <meta name="description" content="Ne gasim motivatia in rezultatele deosebite pe care le obtinem pentru clientii nostri si abordam provocarile cu entuziasm si determinare." />
                <link rel="canonical" href="/portofoliu" />
            </Helmet>

            <NavBar/>
            <PortfolioGallery/>
            <Footer/>
        </div>
    );
};

export default Portfolio;
