import React from 'react';
import ServiceContent from './../../../Layouts/Services'

const Medical = (props) => {
    const title = props.title
    const serviceLink = props.serviceLink
    const contentImage = props.contentImage
    const content = props.content
    const nextServiceTitle = props.nextServiceTitle
    const nextServiceContent = props.nextServiceContent
    const nextServiceButton = props.nextServiceButton
    const nextServiceLink = props.nextServiceLink
    const nextServiceImage = props.nextServiceImage

    return (
        <div>
            <ServiceContent
                title = "
                    Pardoseli pentru domeniul medical
                "

                serviceLink = "/domeniul-medical-farmaceutic"

                contentImage = {require('./../../Images/Photos/medical.jpg')}

                content = "
                    Pardoselile pentru spatiile medicale (cabinete, clinici, spitale), farmaceutice (farmacie) si sanitare
                    prezinta o serie de particularitati, care impun un grad mare de constiinciozitate, rigurozitate si
                    precautie, inclusiv in ceea ce priveste aplicarea unei pardoselii. Aceste cerinte speciale determina
                    anumite standarde de calitate si de protejare a sanatatii si a mediului.

                    Produsele noastre respecta, pe de o parte, prevederile legislative aplicabile in acest domeniu la
                    nivel national, iar, pe de alta parte, normativele europene care vizeaza instalarea si intretinerea
                    pardoselilor din spatiile medicale si farmaceutice. Sistemele de calitate ireprosabila pe care vi le
                    punem la dispozitie sunt performante, rezistente chimic, usor de curatat si intretinut. Proprietatile
                    de pastrare a unui mediu curat reies din proprietatile antistatice pe care le pot avea, precum si
                    din faptul ca nu necesita rosturi in procesul de finisare.
                "
                nextServiceTitle = "
                    Pardoseli pentru industria alimentara
                "
                nextServiceContent = "
                    Este necesar ca o pardoseala folosita in zonele de productie agroalimentara, precum si in depozite,
                    spatii de ambalare sau procesare, sa aiba un grad inalt de rezistenta la actiunea factorilor fizici
                    si chimici cu care poate veni in contact. Suprafata texturata, caracterul antiderapant si rezistenta...
                "
                nextServiceButton = "
                    Citeste mai mult
                "

                nextServiceLink = "industrie-alimentara"

                nextServiceImage = {require('./../../Images/Photos/industrie-alimentara.jpeg')}
            />
        </div>
    );
};

export default Medical;
