import './index.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { TfiUnlink } from "react-icons/tfi";
import {BsArrowRightCircle} from "react-icons/bs";

const NotFound = () => {
    return (
        <div>
            <div className="not-found d-flex flex-wrap justify-content-center align-items-center">
                <div>
                <h1 className="color-green"><TfiUnlink /> 404 PAGE NOT FOUND</h1>
                    <div className="color-white text-center my-4">
                        <div>We are looking everywhere for this page.</div>
                        <div>Are you sure the website URL is correct?</div>
                        <div>Get in touch with site owner.</div>
                    </div>
                    <div className="text-center pt-5">
                        <Link to="/" className="button">Back to home <span className="button-symbol"><BsArrowRightCircle/></span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
