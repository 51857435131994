import React from 'react';
import ServiceContent from './../../../Layouts/Services'

const Sports = (props) => {
    const title = props.title
    const serviceLink = props.serviceLink
    const contentImage = props.contentImage
    const content = props.content
    const nextServiceTitle = props.nextServiceTitle
    const nextServiceContent = props.nextServiceContent
    const nextServiceButton = props.nextServiceButton
    const nextServiceLink = props.nextServiceLink
    const nextServiceImage = props.nextServiceImage

    return (
        <div>
            <ServiceContent
                title = "
                    Pardoseli pentru sali de sport si fitness
                "

                serviceLink = "/sali-de-sport-fitness"

                contentImage = {require('./../../Images/Photos/sali-sport.jpg')}

                content = "
                    Pardoseli pentru suprafetele sportive si zonele publice de agrement care sa raspunda nevoilor si
                    cerintelor specifice respectivelor spatii: sali de sport, sali de fitness, zone de agrement. Aici,
                    sunt necesare pardoseli cu durata de viata extinsa, care sunt antiderapante si usor de intretinut
                    si, mai ales, care au capacitatea de a proteja articulatiile sportivilor. De asemenea, este vorba
                    de sisteme reciclabile, care permit instalarea rapida si care indeplinesc conditiile de calitate ISO,
                    precum si pe cele referitoare la calitatea aerului de interior (Eurofins – calitatea aerului A+).

                    Pentru ca suntem convinsi ca sanatatea si siguranta se situeaza pe primul plan in topul prioritatilor
                    dumneavoastra, va punem la dispozitie pardoseli performante, certificate international, pentru
                    domeniul sport si locuri de agrement. Produsele pe care vi le oferim vizeaza, printre altele,
                    realizarea pardoselilor pentru: piste de atletism (indoor si outdoor), sali de sport, spatii
                    multifunctionale, locuri de joaca. Gama extinsa de sisteme de pardoseala pentru astfel de suprafete
                    cuprinde solutii de aplicare pe care specialistii nostri le implementeaza in vederea obtinerii de
                    rezultate optime, in functie de necesitatile si cerintele fiecarui spatiu dedicat activitatilor sportive
                    sau celor de agrement.
                "
                nextServiceTitle = "
                    Pardoseli pentru birouri si zone urbane
                "
                nextServiceContent = "
                    Pardoselile utilizate in spatii precum cladiri de birouri, biblioteci, showroom-uri sau ansambluri
                    rezidentiale, se diferentiaza de alte sisteme de pardoseala prin cateva caracteristici esentiale.
                    Fiind necesar in spatii cu fluxuri mari de persoane...
                "
                nextServiceButton = "
                    Citeste mai mult
                "

                nextServiceLink = "birouri-zone-urbane"

                nextServiceImage = {require('./../../Images/Photos/spatii-birouri.jpg')}
            />
        </div>
    );
};

export default Sports;
