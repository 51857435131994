import './index.css'
import React from 'react'
import NavBar from "../NavBar";
import Footer from "../Footer";
import {BsArrowRightCircle} from "react-icons/bs";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const ServiceContent = (props) => {
    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.title}</title>
                <meta name="description" content={(props.content).slice(0, 167) + '...'}/>
                <link rel="canonical" href={props.serviceLink} />
            </Helmet>

            <NavBar/>
                <div className="content-page mt-5 pt-5">
                    <div className="container pt-5">
                        <h2 className="color-green my-4 px-3">{props.title}</h2>
                        <div className="row">
                            <div className="col-md-6 gx-5 py-4">
                                <img src={props.contentImage} width="100%" alt="current_service_img"/>
                            </div>
                            <div className="col-md-6 gx-5 py-4">
                                {props.content}
                            </div>
                        </div>
                    </div>
                    <div className="pt-5">
                        <div className="row mt-5 next-banner">
                            <div className="col-md-6 d-flex flex-wrap align-items-center">
                                <div className="container px-4 py-5 py-md-0 px-md-5">
                                    <h2 className="color-green my-4">{props.nextServiceTitle}</h2>
                                    {props.nextServiceContent}
                                    <div className="mt-5 mb-5">
                                        <Link to={'/' + props.nextServiceLink} className="button">{props.nextServiceButton} <span className="button-symbol"><BsArrowRightCircle/></span></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="container text-center">
                                    <div className="next-service-img"
                                     style={{
                                        backgroundImage: `url(${props.nextServiceImage})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    );
};

export default ServiceContent;
