import './index.css'
import React from 'react';
import ImageGallery from 'react-image-gallery';

const PortfolioGallery = () => {

    const images = [];
    const imageNum = Array(78).fill(0).map((e,i)=>i+1);

    imageNum.forEach((imageNum, index) => {
        images.push({
            original: require('./../Images/Portfolio/images/' + imageNum + '.jpeg'),
            thumbnail: require('./../Images/Portfolio/images/' + imageNum + '.jpeg'),
        });
    });

    return (
        <div className="portfolio mt-5 pt-5 py-5 px-3">
            <div className="container my-5">
                <div className="title text-center">
                    <div><h5>PORTOFOLIUL NOSTRU</h5></div>
                    <div className="title-border"></div>
                </div>

                <div className="text-center my-5">
                    Ne gasim motivatia in rezultatele deosebite pe care le obtinem pentru clientii nostri si abordam
                    provocarile cu entuziasm si determinare. Unul dintre principiile care ne ghideaza este abordarea
                    realista si proactiva a proiectelor de care ne ocupam si se materializeaza in demersuri concrete
                    si coerente de a ne dezvolta in permanenta.
                </div>

                <div className="gallery-holder py-5 p-lg-5">
                    <ImageGallery items={images} />
                </div>
            </div>
        </div>
    );
};

export default PortfolioGallery;
