import NavBar from "../Layouts/NavBar"
import HeaderBig from "../Layouts/HeaderBig"
import Presentation from "../Content/Presentation"
import Footer from "../Layouts/Footer"
import Faq from "../Content/Faq"
import Score from "../Content/Score"
import React from 'react';
import Testimonials from "../Content/Testimonials";
import {Helmet} from "react-helmet";
import $ from 'jquery';

const Home = () => {

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Syntech Flooring | Sisteme de pardoseli cu tehnologii performante</title>
                <meta name="description" content="Anti-praf, antistatice și antiderapante. Rezistente la acizi, la coroziune și la trafic intens." />
                <link rel="canonical" href="/" />
            </Helmet>

            <HeaderBig/>
            <NavBar/>
            <Presentation/>
            <Score/>
            <Faq/>
            <Testimonials/>
            <div className="custom-video">
                <video className="video-controls" loop={true} muted={true} autoPlay={true} playsInline={true}>
                    <source className="video" src={require('./../Content/Video/video.mp4')} type="video/mp4"/>
                </video>
            </div>
            <Footer/>
        </div>
    );
};

export default Home;







