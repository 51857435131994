import './index.css'
import React from 'react';
import {BsArrowRightCircle, BsQuestionCircle} from "react-icons/bs";

const Faq = () => {
    return (
        <div className="faq py-5">
            <div className="container">
                <div className="title text-center">
                    <div><h5>INTREBARI FRECVENTE</h5></div>
                    <div className="title-border"></div>
                </div>
                <div className="my-5">
                    <div className="row color-green">
                        <div className="col-1 text-end faq-icon">
                            <BsQuestionCircle/>
                        </div>
                        <div className="col-11">
                            <h5>Cat timp dureaza montajul unei pardoseli?</h5>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-1">

                        </div>
                        <div className="col-11">
                            In functie de suprafata de acoperit dar si de tipul de pardoseala ales, lucrarea dureaza intre
                            2 si 7 zile.
                        </div>
                    </div>
                </div>

                <div className="my-5">
                    <div className="row color-green">
                        <div className="col-1 text-end faq-icon">
                            <BsQuestionCircle/>
                        </div>
                        <div className="col-11">
                            <h5>Cat costa o pardoseala epoxidica?</h5>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-1">

                        </div>
                        <div className="col-11">
                            De asemenea, si costul este calculat in functie de materialul ales si de suprafata de acoperit.
                        </div>
                    </div>
                </div>

                <div className="my-5">
                    <div className="row color-green">
                        <div className="col-1 text-end faq-icon">
                            <BsQuestionCircle/>
                        </div>
                        <div className="col-11">
                            <h5>De ce e mai buna pardoseala epoxidica?</h5>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-1">

                        </div>
                        <div className="col-11">
                            O astfel de podea aduce un plus si un punct de vedere decorativ, oferind culori si aspecte
                            diferite (lucius, semi-lucios sau mat), precum si texturi variate: lina sa accidentata. Mai
                            mult decat atat, in structura pardoselii pot fi introduse elemente de diferite forme si culori,
                            creandu-se astfel un concept de design unic. Poate fi folosita si in spatiile mici, luciul sau
                            reflectand lumina si oferind astfel iluzia unui spatiu mai mare.
                        </div>
                    </div>

                    <div className="text-center mt-5 pt-5">
                        <a href="tel:+40747479634" className="button">Suna acum <span className="button-symbol"><BsArrowRightCircle/></span></a>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Faq;
