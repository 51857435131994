import React from 'react';
import ServiceContent from './../../../Layouts/Services'

const Industry = (props) => {
    const title = props.title
    const serviceLink = props.serviceLink
    const contentImage = props.contentImage
    const content = props.content
    const nextServiceTitle = props.nextServiceTitle
    const nextServiceContent = props.nextServiceContent
    const nextServiceButton = props.nextServiceButton
    const nextServiceLink = props.nextServiceLink
    const nextServiceImage = props.nextServiceImage

    return (
        <div>
            <ServiceContent
                title = "
                    Pardoseli pentru industria producatoare si hale industriale
                "

                serviceLink = "/industrie-hale-industriale"

                contentImage = {require('./../../Images/Photos/hale-industriale.jpg')}

                content = "
                    Pardoselile industriale propuse de noi acopera o gama larga de intrebuintari in domeniul industrial.
                    Pardoselile au la baza tehnologii diverse si produse superioare, performante, care au capacitatea de
                    a raspunde nevoilor si cerintelor specifice ale oricarui proiect de aplicare in zone industriale.
                    Prin implementarea unor sisteme variate, de la terrazzo la mortare poliuretanice, de la sisteme
                    epoxidice la sisteme poliuretanice, de la MMA la sisteme din cuart, echipa noastra de specialisti
                    va contribui la realizarea unei lucrari de calitate, rezistente si durabile in timp. Ne consideram
                    partenerul clientilor nostri, impreuna cu care stabilim termenii de executie si cerintele particulare
                    pentru fiecare proiect de pardosire.

                    Sistemele de pardoseala pe care vi le propunem sunt recunoscute ca indeplinind functii dintre cele
                    mai diverse, fiind rezistente la agenti abrazivi si la trafic sustinut, respectand, totodata, si
                    standardele de calitate, de design si de protejare a sanatatii si a mediului. Daca doriti
                    implementarea unui sistem industrial solid si de durata, modern si util, va invitam sa ne contactati
                    cu incredere. Specialistii nostri va stau la dispozitie cu solutii viabile de pardoseala pentru hale,
                    fabrici, linii de productie sau asamblare, hangare, depozite si nu numai.
                "
                nextServiceTitle = "
                    Pardoseli pentru domeniul medical
                "
                nextServiceContent = "
                    Pardoselile pentru spatiile medicale (cabinete, clinici, spitale), farmaceutice (farmacie) si
                    sanitare prezinta o serie de particularitati, care impun un grad mare de constiinciozitate,
                    rigurozitate si precautie, inclusiv in ceea ce priveste aplicarea unei pardoselii. Aceste cerinte...
                "
                nextServiceButton = "
                    Citeste mai mult
                "

                nextServiceLink = "domeniul-medical-farmaceutic"

                nextServiceImage = {require('./../../Images/Photos/medical.jpg')}
            />
        </div>
    );
};

export default Industry;
