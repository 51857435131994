import './index.css'
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import React from "react";

const Score = () => {





    return (
        <div className="score color-white py-3">


            <div className="score-image">
                <div className="score-image-backdrop d-flex flex-wrap justify-content-center align-items-center">
                    <div>
                        <div>
                            <div className="title container text-center py-5">
                                <div><h5>INCREDERE ACORDATA DE PESTE 150 DE CLIENTI</h5></div>
                                <div className="title-border"></div>
                            </div>
                            <div className="container text-center py-4">
                                Echipa noastra s-a bucurat de colaborarea eficienta cu peste 100 de furnizori si realizarea
                                cu succes a proiectelor demarate impreuna cu peste 150 de clienti. Si datorita lor, putem
                                atinge, astazi, performante de durata, bazate pe imbunatatiri continue ale proceselor de lucru.
                            </div>
                            <div className="container py-4">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12 col-md-6 col-lg-3 row">
                                        <div className="col-5 color-green text-start text-end counter-digits">
                                            +<CountUp end="250" redraw={true}>
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start}>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                        </div>
                                        <div className="col-2 d-flex flex-wrap align-items-center counter-text">
                                            CLIENTI <br/> MULTUMITI
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-3 row">
                                        <div className="col-5 color-green test-start text-end counter-digits">
                                            +<CountUp end="200" redraw={true}>
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start}>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                        </div>
                                        <div className="col-2 d-flex flex-wrap align-items-center counter-text">
                                            PROIECTE <br/> FINALIZATE
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-3 row">
                                        <div className="col-5 color-green test-start text-end counter-digits">
                                            +<CountUp end="150" redraw={true}>
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start}>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                        </div>
                                        <div className="col-2 d-flex flex-wrap align-items-center counter-text">
                                            PARTENERI <br/> AFACERI
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-3 row">
                                        <div className="col-5 color-green text-start text-end counter-digits">
                                            +<CountUp end="20" redraw={true}>
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start}>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                        </div>
                                        <div className="col-2 d-flex flex-wrap align-items-center counter-text">
                                            ANI DE <br/> EXPERIENTA
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Score;
