import './index.css'
import React from 'react'

const Testimonials = () => {
    return (
        <div className="testimonials-bg py-5">

            <div className="container">
                <h2 className='color-green'>Ce spun clientii?</h2>
            </div>

            <div className="my-3">
                <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="container-fluid ">
                                <div className="row justify-content-center">
                                    <div className="col-sm-11 col-md-9 col-lg-8 col-xl-7">
                                        <div className="card-testimonials">
                                            <p className="post">
                                                <span className="post-txt">
							                        Multumim baietilor de la Syntech Flooring. Nu suntem la prima
                                                    colaborare si cu siguranta nici la ultima. Mereu ⭐⭐⭐⭐⭐ service.
                                                </span>
                                            </p>
                                        </div>
                                        <div className="arrow-down"></div>
                                        <div className="row d-flex justify-content-center">
                                            <div className="">
                                                <img className="profile-pic fit-image" src={require('../Images/Testimonials/testimonial_1.jpg')}></img>
                                            </div>
                                            <p className="profile-name">Xtreme PDR</p>
                                            <p className="profile-type">Clienti Industrial</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="container-fluid ">
                                <div className="row justify-content-center">
                                    <div className="col-sm-11 col-md-9 col-lg-8 col-xl-7">
                                        <div className="card-testimonials">
                                            <p className="post">
                                                <span className="post-txt">
							                        Am amenajat o hala cu o suprafata de 150 mp. Totul a decurs
                                                    exceptional iar cooperarea cu Syntech Floor a fost nemaipomenita.
                                                    Urmeaza un alt proiect.
                                                </span>
                                            </p>
                                        </div>
                                        <div className="arrow-down"></div>
                                        <div className="row d-flex justify-content-center">
                                            <div className="">
                                                <img className="profile-pic fit-image" src={require('../Images/Testimonials/testimonial_2.jpg')}></img>
                                            </div>
                                            <p className="profile-name">Fresh Start</p>
                                            <p className="profile-type">Clienti Industrial</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="container-fluid ">
                                <div className="row justify-content-center">
                                    <div className="col-sm-11 col-md-9 col-lg-8 col-xl-7">
                                        <div className="card-testimonials">
                                            <p className="post">
                                                <span className="post-txt">
							                        Raportul calitate/pret bate toate asteptarile. Pardoseala se
                                                    intretine excelent, iar textura si designul se incadreaza perfect
                                                    in living.
                                                </span>
                                            </p>
                                        </div>
                                        <div className="arrow-down"></div>
                                        <div className="row d-flex justify-content-center">
                                            <div className="">
                                                <img className="profile-pic fit-image" src={require('../Images/Testimonials/testimonial_3.jpg')}></img>
                                            </div>
                                            <p className="profile-name">Dana Apostol</p>
                                            <p className="profile-type">Clienti Casnic</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="container-fluid ">
                                <div className="row justify-content-center">
                                    <div className="col-sm-11 col-md-9 col-lg-8 col-xl-7">
                                        <div className="card-testimonials">
                                            <p className="post">
                                                <span className="post-txt">
							                        O echipa perfecta. Rezultatul mi-a depasit cu mult asteptarile.
                                                    Lucrarea a fost executata perfect si la timp.
                                                </span>
                                            </p>
                                        </div>
                                        <div className="arrow-down"></div>
                                        <div className="row d-flex justify-content-center">
                                            <div className="">
                                                <img className="profile-pic fit-image" src={require('../Images/Testimonials/testimonial_4.jpg')}></img>
                                            </div>
                                            <p className="profile-name">Marian Antohi</p>
                                            <p className="profile-type">Clienti Industrial</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a className="carousel-control-prev" href="#myCarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon"></span>
                    </a>
                    <a className="carousel-control-next" href="#myCarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon"></span>
                    </a>
                </div>
            </div>












        </div>
    );
};

export default Testimonials;
