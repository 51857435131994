import './index.css'
import React from 'react';
import { BsArrowRightCircle } from "react-icons/bs";
import {Link} from "react-router-dom";


const HeaderBig = () => {
    return (
        <div>
            <div className="header-big-image">
                <div className="wash-color">
                    <div className="backdrop">
                        <div className="hero-wrapper p-3 row">
                            <div className="col-10">
                                <div className="hero-title color-white">
                                    <h1>Sisteme de pardoseli cu tehnologii <br/> performante</h1>
                                </div>
                                <div className="hero-paragraph color-white">
                                    <h5>Anti-praf, antistatice și antiderapante. Rezistente la acizi, la coroziune și la trafic intens.</h5>
                                </div>
                                <div className="text-center mt-5">
                                    <Link to="/servicii" className="button">Servicii <span className="button-symbol"><BsArrowRightCircle/></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderBig;
