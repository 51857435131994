import React from 'react';
import NavBar from "../Layouts/NavBar";
import Footer from "../Layouts/Footer";
import CarouselServices from "../Content/Carousel";
import {Helmet} from "react-helmet";

const Services = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Serviciile oferite de Syntech Flooring</title>
                <meta name="description" content="Implicarea este esentiala in orice echipa. Fara oameni de calitate, dedicati si ambitiosi, ar fi fost mai greu sa ajungem unde suntem astazi." />
                <link rel="canonical" href="/servicii" />
            </Helmet>

            <NavBar/>
            <CarouselServices/>
            <Footer/>
        </div>
    );
};

export default Services;
