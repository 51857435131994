import React from 'react';
import ServiceContent from './../../../Layouts/Services'

const Commercial = (props) => {
    const title = props.title
    const serviceLink = props.serviceLink
    const contentImage = props.contentImage
    const content = props.content
    const nextServiceTitle = props.nextServiceTitle
    const nextServiceContent = props.nextServiceContent
    const nextServiceButton = props.nextServiceButton
    const nextServiceLink = props.nextServiceLink
    const nextServiceImage = props.nextServiceImage

    return (
        <div>
            <ServiceContent
                title = "
                    Pardoseli pentru spatii publice si comerciale
                "

                serviceLink = "/spatii-publice-comerciale"

                contentImage ={require('./../../Images/Photos/spatii-comerciale.jpg')}

                content = "
                    Pardoselile destinate spatiilor publice cu trafic intens, precum cele din domeniul retail, trebuie
                    sa intruneasca o serie de specificatii si caracteristici speciale, care sa fie in conformitate cu
                    destinatiile de aplicare. Robustete, durabilitate, rezistenta la uzura si abraziune, design placut,
                    confort, proprietati antistatice, parametri de reducere a zgomotului, usurinta in intretinere –
                    toate acestea sunt esentiale, in alegerea pardoselii optime pentru spatii comerciale. Sistemele de
                    pardoseli publice implementate de noi in perimetre cu caracter comercial, de trafic intens, respecta
                    toate aceste standarde si nu numai. Un grad sporit de atentie este acordat normelor de siguranta si
                    sanatate.

                    Fiind vorba de un flux ridicat de persoane, nu putem decat sa constientizam importanta unei pardoseli
                    de calitate, rezistente, care sa corespunda atat cerintelor dumneavoastra, cat si nevoilor implicite
                    ale tuturor celor care vor tranzita spatiul respectiv.

                    Consideram, asadar, ca o cerinta necesara si obligatorie in alegerea sistemului optim pentru domeniul
                    retail este aceea de a stabili, impreuna, solutia de pardosire ideala pentru spatiul pe care doriti
                    sa il amenajati. Va garantam o decizie informata si personalizata, pe baza unei game de produse variate.
                "
                nextServiceTitle = "
                    Pardoseli pentru parcari si domeniul auto
                "
                nextServiceContent = "
                    O parcare sau un spatiu pentru auto este, prin definitie, o constructie diferita de oricare restul,
                    din numeroase puncte de vedere. Intr-o parcare, este necesar ca stalpii de sustinere sa fie cat mai
                    putini la numar, din considerente ce tin de eficientizarea utilizarii spatiului interior. In schimb,
                    o astfel de...
                "
                nextServiceButton = "
                    Citeste mai mult
                "

                nextServiceLink = "parcari-auto"

                nextServiceImage = {require('./../../Images/Photos/parcari-auto.jpg')}
            />
        </div>
    );
};

export default Commercial;
