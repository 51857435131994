import React from 'react';
import ServiceContent from './../../../Layouts/Services'

const Urban = (props) => {
    const title = props.title
    const serviceLink = props.serviceLink
    const contentImage = props.contentImage
    const content = props.content
    const nextServiceTitle = props.nextServiceTitle
    const nextServiceContent = props.nextServiceContent
    const nextServiceButton = props.nextServiceButton
    const nextServiceLink = props.nextServiceLink
    const nextServiceImage = props.nextServiceImage

    return (
        <div>
            <ServiceContent
                title = "
                    Pardoseli pentru birouri si zone urbane
                "

                serviceLink = "/birouri-zone-urbane"

                contentImage = {require('./../../Images/Photos/spatii-birouri.jpg')}

                content = "
                    Pardoselile utilizate in spatii precum cladiri de birouri, biblioteci, showroom-uri sau ansambluri
                    rezidentiale, se diferentiaza de alte sisteme de pardoseala prin cateva caracteristici esentiale.
                    Fiind necesar in spatii cu fluxuri mari de persoane, acest tip de sistem de pardoseala reuneste
                    particularitati ce nu tin doar de aspect sau finisaj, ci si de proprietati precum nivelul de confort
                    oferit si capacitatea de a reduce zgomotul. Pe langa durabilitate si robustete, o importanta
                    deosebita o prezinta specificatiile referitoare la siguranta, precum si respectarea normelor privind
                    mentinerea sanatatii. Un criteriu suplimentar in alegerea unui sistem de pardoseala pentru birouri
                    sau cladiri rezidentiale private il constituie optiunile de culoare disponibile, un detaliu care
                    poate contribui, intr-o masura foarte mare, la aspectul general al spatiului vizat.

                    Va oferim posibilitatea de a alege solutia optima in materie de sisteme de pardoseala. Produsele si
                    tehnologiile pe care le comercializam raspund celor mai diverse nevoi si celor mai inalte standarde.
                "
                nextServiceTitle = "
                    Pardoseli pentru spatii publice si comerciale
                "
                nextServiceContent = "
                    Pardoselile destinate spatiilor publice cu trafic intens, precum cele din domeniul retail, trebuie
                    sa intruneasca o serie de specificatii si caracteristici speciale, care sa fie in conformitate cu
                    destinatiile de aplicare. Robustete, durabilitate...
                "
                nextServiceButton = "
                    Citeste mai mult
                "

                nextServiceLink = "spatii-publice-comerciale"

                nextServiceImage = {require('./../../Images/Photos/spatii-comerciale.jpg')}
            />
        </div>
    );
};

export default Urban;
