import './index.css'
import React, {useState, useRef} from 'react';
import {BsPinMap, BsTelephoneOutbound} from "react-icons/bs";
import {AiOutlineMail} from "react-icons/ai";
import $ from 'jquery';
import axios from 'axios';

const API_PATH = 'https://epoxfloor.ro/api/index.php';

class ContactDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            mailSent: false,
            error: null
        }
    }

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(result => {
                this.setState({
                    mailSent: result.data.sent,
                    name: '',
                    email: '',
                    message: '',
                })
            })
            .catch(error => this.setState({ error: error.message }));
    };



    render() {
        return (
            <div className="contact mt-5">
                <div className="container text-center pt-5">
                    <div className="title pt-5">
                        <div><h5>NE BUCURAM SA VA RASPUNDEM</h5></div>
                        <div className="title-border"></div>
                    </div>
                    <div className="py-3">
                        Daca doriti amanunte despre produsele noastre sau sugestii pentru proiectele dvs. nu ezitati sa ne contactati!
                    </div>
                    <div>
                        <div className="row mb-5 pb-5 d-flex justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-12 text-start my-5 d-md-flex align-items-md-start justify-content-md-center">
                                <div className="row pt-1 mt-md-4">
                                    <div className="col-sm-2 col-md-12 col-lg-12 text-center footer-icons">
                                        <BsPinMap/>
                                    </div>
                                    <div className="col-sm-10 col-md-12 col-lg-12 text-center">
                                        <div>LOCATIE</div>
                                        <div><a
                                            href="https://www.google.com/maps/dir//Gala%C8%9Bi,+800402/@45.4244109,28.0128862,17.24z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40b6deea93f48937:0x42417bc5413e096d!2m2!1d28.0154315!2d45.4241933"
                                            target="_blank" className="text-decoration-none text-white">Str.
                                            Narciselor, bl. G2A <br/> Galati </a></div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-sm-2 col-md-12 col-lg-12 text-center footer-icons">
                                        <AiOutlineMail/>
                                    </div>
                                    <div className="col-sm-10 col-md-12 col-lg-12 text-center">
                                        <div>EMAIL</div>
                                        <div><a href="mailto:office@epoxfloor.ro"
                                                className="text-decoration-none text-white">office@epoxfloor.ro</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-sm-2 col-md-12 col-lg-12 text-center footer-icons">
                                        <BsTelephoneOutbound/>
                                    </div>
                                    <div className="col-sm-10 col-md-12 col-lg-12 text-center">
                                        <div>TELEFON</div>
                                        <div><a href="tel:+40747479634" className="text-decoration-none text-white">+4
                                            0747 479 634</a></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-8 text-start my-5">
                                <div className="message-card">
                                    <form action="#">
                                        <div className="bg-success mb-4 text-center d-none" id="response">
                                            Mesajul tau a fost trimis cu success!
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="name">
                                                Nume
                                            </label>
                                            <input className="form-control custom-input" type="text" name="name"
                                                   id="name" autoComplete="off" required
                                                   value={this.state.name}
                                                   onChange={e => this.setState({ name: e.target.value })}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="email">
                                                Email
                                            </label>
                                            <input className="form-control custom-input" type="email" name="email"
                                                   id="email" autoComplete="off" required
                                                   value={this.state.email}
                                                   onChange={e => this.setState({ email: e.target.value })}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="message">
                                                Mesaj
                                            </label>
                                            <textarea className="form-control custom-input" name="message" id="message"
                                                      autoComplete="off" required
                                                      onChange={e => this.setState({ message: e.target.value })}
                                                      value={this.state.message}
                                            />
                                        </div>

                                        <div className="text-end">
                                            <button type="submit"
                                                    onClick={e => this.handleFormSubmit(e)}
                                                    className="btn btn-success mt-3" value="submit">Trimite
                                            </button>
                                        </div>

                                        <div>
                                            {this.state.mailSent &&
                                                <div>Multumim pentru ca ne-ai contactat.</div>
                                            }
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="map-wrap pb-5">
                    <div className="map-holder">
                        <div className="map-backdrop">
                            <a href="https://www.google.com/maps/dir//Gala%C8%9Bi,+800402/@45.4244109,28.0128862,17.24z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40b6deea93f48937:0x42417bc5413e096d!2m2!1d28.0154315!2d45.4241933"
                               target="_blank">
                                <div className="hot-link"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default ContactDetails;
