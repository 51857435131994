import './index.css'
import React from 'react';
import { BsPinMap } from "react-icons/bs";
import { BsTelephoneOutbound } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { ReactComponent as Logo } from "./../../Content/Images/logo.svg";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer small">
            <div className="top-footer">
                <div className="container p-4 pt-5">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12 my-2">
                            <div className="color-green mb-3">DESPRE NOI</div>
                            <div className="mb-3">
                                <Logo height="64" width="120" alt="SynTech_Logo"/>
                            </div>
                            <div className="padding-right">
                                Echipa noastra s-a bucurat de colaborarea eficienta cu peste 100 de funizori si realizarea
                                cu succes a proiectelor demarate impreuna cu peste 150 de clienti
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-12 my-2">
                            <div className="color-green mb-3">SERVICII</div>
                            <div>
                                <ul>
                                    <li><Link className="footer-link" to="/sali-de-sport-fitness">Sali de sport si fitness</Link></li>
                                    <li><Link className="footer-link" to="/birouri-zone-urbane">Birouri si zone urbane</Link></li>
                                    <li><Link className="footer-link" to="/spatii-publice-comerciale">Spatii publice si comerciale</Link></li>
                                    <li><Link className="footer-link" to="/parcari-auto">Parcari si domeniul auto</Link></li>
                                    <li><Link className="footer-link" to="/industrie-hale-industriale">Hale industriale</Link></li>
                                    <li><Link className="footer-link" to="/domeniul-medical-farmaceutic">Domeniul medical</Link></li>
                                    <li><Link className="footer-link" to="/industrie-alimentara">Industria alimentara</Link></li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-12 my-2 mt-lg-0">
                            <div className="color-green mb-3">MENIU</div>
                            <div>
                                <ul>
                                    <li><Link className="footer-link" to="/">Cine suntem noi?</Link></li>
                                    <li><Link className="footer-link" to="/portofoliu">Portofoliul epoxfloor</Link></li>
                                    <li><Link className="footer-link" to="/servicii">Servicii Pardoseli</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 my-2 mt-4 mt-lg-0 mt-md-5">
                            <div className="color-green mb-3">CONTACT</div>
                            <div className="row">
                                <div className="col-2 footer-icons">
                                    <BsPinMap/>
                                </div>
                                <div className="col-10">
                                    <div>LOCATIE</div>
                                    <div>
                                        <a href="https://www.google.com/maps/dir//Gala%C8%9Bi,+800402/@45.4244109,28.0128862,17.24z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40b6deea93f48937:0x42417bc5413e096d!2m2!1d28.0154315!2d45.4241933" target="_blank" className="footer-link">
                                            Galati, Str. Narciselor, bl. G2A, ap. 16
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-2 footer-icons">
                                    <AiOutlineMail/>
                                </div>
                                <div className="col-10">
                                    <div>EMAIL</div>
                                    <div><a href="mailto:office@epoxfloor.ro" className="footer-link">office@epoxfloor.ro</a></div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-2 footer-icons">
                                    <BsTelephoneOutbound/>
                                </div>
                                <div className="col-10">
                                    <div>TELEFON</div>
                                    <div><a href="tel:+40747479634" className="footer-link">+4 0747 479 634</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright small">
                © {new Date().getFullYear()} - SynTech Flooring. Coded by <a href="https://snapdesign.ro" target="_blank" className="text-white text-decoration-none mx-1"> SnapDesign</a>
            </div>
        </div>

    );
};

export default Footer;
