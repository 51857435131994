import React from 'react';
import ServiceContent from './../../../Layouts/Services'

const Automobile = (props) => {
    const title = props.title
    const serviceLink = props.serviceLink
    const contentImage = props.contentImage
    const content = props.content
    const nextServiceTitle = props.nextServiceTitle
    const nextServiceContent = props.nextServiceContent
    const nextServiceButton = props.nextServiceButton
    const nextServiceLink = props.nextServiceLink
    const nextServiceImage = props.nextServiceImage

    return (
        <div>
            <ServiceContent
                title = "
                    Pardoseli pentru parcari si domeniul auto
                "

                serviceLink = "/parcari-auto"

                contentImage = {require('./../../Images/Photos/parcari-auto.jpg')}

                content = "
                    O parcare sau un spatiu pentru auto este, prin definitie, o constructie diferita de oricare restul,
                    din numeroase puncte de vedere. Intr-o parcare, este necesar ca stalpii de sustinere sa fie cat mai
                    putini la numar, din considerente ce tin de eficientizarea utilizarii spatiului interior. In schimb,
                    o astfel de cladire prezinta un numar ridicat de grinzi si, implicit, necesita solutii adecvate, in
                    materie de pardoseala. Nivelul de vibratii pe care trebuie sa il suporte o parcare este ridicat si,
                    adeseori, puternic si se datoreaza traficului de masini din respectiva zona. Solicitarile impuse de
                    parcarile auto multietajate deschise sunt si mai speciale. In lipsa peretilor exteriori, elementele
                    structurale ale acestui tip de sisteme sunt supuse, in mod diferentiat, la factori externi diversi
                    si variabili, precum: temperatura, radiatii UV, conditii climatice sezoniere, frecvente sau extreme.
                    Acest lucru inseamna ca pardoselile folosite pentru parcarile descoperite trebuie sa respecte o
                    serie de reguli si mai stricte, in ceea ce priveste rezistenta la fisuri si deteriorari, precum si
                    durabilitatea in timp.

                    Ținand cont de factorii repetati si puternici care pot afecta o structura solida, va oferim solutii
                    veritabile la toate potentialele probleme identificate. Ne face onoare sa avem, deja, in portofoliul
                    nostru, lucrari de pardoseli pentru parcari si ne-am bucura sa discutam si particularitatile
                    proiectului dumneavoastra.
                "
                nextServiceTitle = "
                    Pardoseli pentru industria producatoare si hale industriale
                "
                nextServiceContent = "
                    Pardoselile industriale propuse de noi acopera o gama larga de intrebuintari in domeniul industrial.
                    Pardoselile au la baza tehnologii diverse si produse superioare, performante, care au capacitatea
                    de a raspunde nevoilor si cerintelor specifice ale oricarui proiect de aplicare in zone industriale...
                "
                nextServiceButton = "
                    Citeste mai mult
                "

                nextServiceLink = "industrie-hale-industriale"

                nextServiceImage ={require('./../../Images/Photos/hale-industriale.jpg')}
            />
        </div>
    );
};

export default Automobile;
