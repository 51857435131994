import React from 'react';
import NavBar from "../Layouts/NavBar";
import Footer from "../Layouts/Footer";
import ContactDetails from "../Content/Contact";
import {Helmet} from "react-helmet";

const Contact = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact | Ne bucuram sa va raspundem</title>
                <meta name="description" content="Galati, str. Narciselor, bl. G2A, ap. 16. Telefon: +4 0747 479 634. Email: office@epoxfloor.ro" />
                <link rel="canonical" href="/contact" />
            </Helmet>

            <NavBar/>
            <ContactDetails/>
            <Footer/>
        </div>
    );
};

export default Contact;
