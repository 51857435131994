import React from 'react';
import ServiceContent from './../../../Layouts/Services'

const Food = (props) => {
    const title = props.title
    const serviceLink = props.serviceLink
    const contentImage = props.contentImage
    const content = props.content
    const nextServiceTitle = props.nextServiceTitle
    const nextServiceContent = props.nextServiceContent
    const nextServiceButton = props.nextServiceButton
    const nextServiceLink = props.nextServiceLink
    const nextServiceImage = props.nextServiceImage

    return (
        <div>
            <ServiceContent
                title = "
                    Pardoseli pentru industria alimentara
                "

                serviceLink = "/industrie-alimentara"

                contentImage = {require('./../../Images/Photos/industrie-alimentara.jpeg')}
                content = "
                    Este necesar ca o pardoseala folosita in zonele de productie agroalimentara, precum si in depozite,
                    spatii de ambalare sau procesare, sa aiba un grad inalt de rezistenta la actiunea factorilor fizici si
                    chimici cu care poate veni in contact. Suprafata texturata, caracterul antiderapant si rezistenta,
                    durabilitatea si usurinta in curatare – toate aceste trasaturi sunt esentiale in alegerea sistemului
                    de pardoseala pentru spatii agroalimentare.

                    Sistemele de pardoseli pe care le realizam pentru domeniul agroalimentar consta in solutii viabile,
                    adaptabile atat cerintelor specifice ale proiectului dumnevoastra, cat si celor mai exigente norme de
                    productie si cerintelor referitoare la securitatea in munca, promovarea unui mediu de lucru curat si
                    protectia mediului inconjurator.
                "
                nextServiceTitle = "
                    Portofoliul SynTech Flooring
                "
                nextServiceContent = "
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam corporis facere,
                    inventore laudantium libero molestiae molestias officiis optio quibusdam rem repellendus. Hic, in,
                    iusto? Laudantium, neque sequi. Animi cupiditate fuga fugit incidunt natus numquam odio quisquam.
                "
                nextServiceButton = "
                    Vezi Portofoliu
                "

                nextServiceLink = "portofoliu"

                nextServiceImage = {require('./../../Images/Photos/portofoliu.jpg')}
            />
        </div>
    );
};

export default Food;
