import React from 'react';
import NotFound from "../Content/PageNotFound";

const PageNotFound = () => {
    return (
        <div>
            <NotFound/>
        </div>
    );
};

export default PageNotFound;
