import './index.css'
import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel';
import {Link} from "react-router-dom";

const CarouselServices = () => {
    return (
        <div className="carousel-wrapper mt-5 pt-5">
            <div className="container py-5">
                <div className="title text-center pb-5 px-4">
                    <div><h5>SERVICIILE OFERITE DE SYNTECH FLOORING</h5></div>
                    <div className="title-border"></div>
                </div>

                <div className="row d-flex flex-wrap justify-content-center">
                    <div className="col-md-12 px-4 mb-5 text-center">
                        <h2 className="mb-4 color-green">Ce ne motiveaza?</h2>
                        <div>
                            Implicarea este esentiala in orice echipa. Fara oameni de calitate, dedicati si ambitiosi, ar fi fost mai greu sa ajungem unde suntem astazi. In prezent, echipa Syntech Floor reuneste peste 55 de persoane cu un potential exceptional, care depun eforturi sustinute de a evolua simultan cu fiecare proiect implementat.<br/>
                            {/*<br/> Ne gasim motivatia in rezultatele deosebite pe care le obtinem pentru clientii nostri si abordam provocarile cu entuziasm si determinare. Unul dintre principiile care ne ghideaza este abordarea realista si proactiva a proiectelor de care ne ocupam si se materializeaza in demersuri concrete si coerente de a ne dezvolta in permanenta.*/}
                        </div>
                    </div>

                    <div className="col-md-12 my-4 px-3">
                        <Carousel showArrows={true} autoPlay={true} showThumbs={false} emulateTouch={true}>
                            <div>
                                <img src={require('./../Images/Photos/sali-sport.jpg')} />
                                <p className="legend">Pardoseli pentru suprafetele sportive si zonele publice de agrement</p>
                            </div>
                            <div>
                                <img src={require('./../Images/Photos/spatii-birouri.jpg')} />
                                <p className="legend">Pardoseli utilizate in spatii precum cladiri de birouri, biblioteci, showroom-uri</p>
                            </div>
                            <div>
                                <img src={require('./../Images/Photos/spatii-comerciale.jpg')} />
                                <p className="legend">Pardoseli destinate spatiilor publice cu trafic intens</p>
                            </div>
                            <div>
                                <img src={require('./../Images/Photos/parcari-auto.jpg')} />
                                <p className="legend">Pardoseli destinate parcarii sau un spatiu pentru auto</p>
                            </div>
                            <div>
                                <img src={require('./../Images/Photos/hale-industriale.jpg')} />
                                <p className="legend">Pardoselile industriale propuse de noi acopera o gama larga</p>
                            </div>
                            <div>
                                <img src={require('./../Images/Photos/medical.jpg')} />
                                <p className="legend">Pardoseli pentru spatiile medicale (cabinete, clinici, spitale), farmaceutice (farmacie)</p>
                            </div>
                            <div>
                                <img src={require('./../Images/Photos/industrie-alimentara.jpeg')} />
                                <p className="legend">Pardoseli folosite in zonele de productie alimentara</p>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="services-wrapper pt-5 px-3">
                <div className="container py-5">
                    <div className="row">
                        <h2 className="color-green mb-4">Ce servicii oferim?</h2>
                        <div>
                            Echipa SynTech Flooring ofera materiale si tehnologie pentru aplicarea pardoselilor pe baza
                            de rasina precum si consultanta de specialitate privind alegerea si aplicarea pardoselilor si
                            mortarelor epoxidice. Serviciile noastre includ:
                        </div>
                        <div className="mt-5">
                            <h5 className="color-green my-3">1. Solutii pentru pardoseli din rasina personalizate</h5>
                            Avand o gama foarte larga de produse/sisteme, avem posibilitatea sa propunem solutii adaptate
                            nevoilor si cerintelor specifice ale oricarui proiect de constructie. Avem peste 20 de ani de
                            experienta in proiectarea pardoselilor pentru incaperi si zone cu utilizari diferite.
                        </div>

                        <div className="mt-5">
                            <h5 className="color-green my-3">2. Asistenta tehnica in santier</h5>
                            Consultantii nostri tehnici folosesc cunostintele si experienta lor pentru a va oferi asistenta
                            la fata locului/santier inainte si in timpul aplicarii solutiilor noastre pentru pardoselile
                            pe baza de rasina. Informatiile va pot ajuta sa evaluati calitatea stratului suport, sa alegeti
                            conditile potrivite pentru a incepe aplicarea sau sa ofere sfaturi despre tehnologia pardoselii.



                            <div className="my-4">
                            Oferim o abordare individuala pentru fiecare proiect. Daca este necesar, modificam formulele in
                            mod regulat si dezvoltam noi solutii astfel incat sistemul de pardoseli sa poata fi adaptat la
                            cerintele specifice fiecarui proiect.
                            </div>
                        </div>

                        <div className="mt-5">
                            <h5 className="color-green my-3">3. Mentenanta, servicii de curatenie si intretinere.</h5>
                            Pentru fiecare proiect in parte, la cerere oferim servicii de mentenanta, curatare cu utilaje
                            profesionale folosind abur si solutii profesionale de curatare.
                        </div>

                    </div>
                    <div className="row my-5 py-5 text-center">
                        <div className="col-md-6 col-lg-4">
                            <Link className="services-link" to="/sali-de-sport-fitness">
                                <div className="services-card text-start">
                                    <div className="services-image-wrapper">
                                        <img className="services-image"
                                             src={require('./../Images/Photos/sali-sport.jpg')}
                                             alt="services"/>
                                    </div>
                                    <div className="my-3">Pardoseli pentru sali de sport si fitness</div>
                                    <div className="services-separator"></div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <Link className="services-link" to="/birouri-zone-urbane">
                                <div className="services-card text-start">
                                    <div className="services-image-wrapper">
                                        <img className="services-image"
                                             src={require('./../Images/Photos/spatii-birouri.jpg')}
                                             alt="services"/>
                                    </div>
                                    <div className="my-3">Pardoseli pentru birouri si zone urbane</div>
                                    <div className="services-separator"></div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <Link className="services-link" to="/spatii-publice-comerciale">
                                <div className="services-card text-start">
                                    <div className="services-image-wrapper">
                                        <img className="services-image"
                                             src={require('./../Images/Photos/spatii-comerciale.jpg')}
                                             alt="services"/>
                                    </div>
                                    <div className="my-3">Pardoseli pentru spatii publice si comerciale</div>
                                    <div className="services-separator"></div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link className="services-link" to="/parcari-auto">
                                <div className="services-card text-start">
                                    <div className="services-image-wrapper">
                                        <img className="services-image"
                                             src={require('./../Images/Photos/parcari-auto.jpg')}
                                             alt="services"/>
                                    </div>
                                    <div className="my-3">Pardoseli pentru parcari si domeniul auto</div>
                                    <div className="services-separator"></div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <Link className="services-link" to="/industrie-hale-industriale">
                                <div className="services-card text-start">
                                    <div className="services-image-wrapper">
                                        <img className="services-image"
                                             src={require('./../Images/Photos/hale-industriale.jpg')}
                                             alt="services"/>
                                    </div>
                                    <div className="my-3">Pardoseli pentru industria producatoare si hale industriale</div>
                                    <div className="services-separator"></div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <Link className="services-link" to="/domeniul-medical-farmaceutic">
                                <div className="services-card text-start">
                                    <div className="services-image-wrapper">
                                        <img className="services-image"
                                             src={require('./../Images/Photos/medical.jpg')}
                                             alt="services"/>
                                    </div>
                                    <div className="my-3">Pardoseli pentru domeniul medical</div>
                                    <div className="services-separator"></div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link className="services-link" to="/industrie-alimentara">
                                <div className="services-card text-start">
                                    <div className="services-image-wrapper">
                                        <img className="services-image"
                                             src={require('./../Images/Photos/industrie-alimentara.jpeg')}
                                             alt="services"/>
                                    </div>
                                    <div className="my-3">Pardoseli pentru industria alimentara</div>
                                    <div className="services-separator"></div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CarouselServices;
