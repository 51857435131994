import './App.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Pages"
import Contact from "./Pages/contact"
import PageNotFound from "./Pages/404"
import Portfolio from "./Pages/portfolio"
import Services from "./Pages/services"
import Sports from "./Content/Services/Sports";
import Urban from "./Content/Services/Urban";
import Commercial from "./Content/Services/Commercial";
import Automobile from "./Content/Services/Automobile";
import Industry from "./Content/Services/Industry";
import Medical from "./Content/Services/Medical";
import Food from "./Content/Services/Food";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/servicii" element={<Services/>} />
                <Route path="/portofoliu" element={<Portfolio/>} />
                <Route path="/sali-de-sport-fitness" element={<Sports/>} />
                <Route path="/birouri-zone-urbane" element={<Urban/>} />
                <Route path="/spatii-publice-comerciale" element={<Commercial/>} />
                <Route path="/parcari-auto" element={<Automobile/>} />
                <Route path="/industrie-hale-industriale" element={<Industry/>} />
                <Route path="/domeniul-medical-farmaceutic" element={<Medical/>} />
                <Route path="/industrie-alimentara" element={<Food/>} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
